import { createStore } from "vuex";
import emitter from "../js/mitt.js";
import admin from "./admin";

const store = createStore({
    modules: {
        admin: admin,
    },
    state() {
        return {
            // Student states
            loggedUser: "",
            profileImageUrl: "",
            FavoriteCount: 0,
            openModal: false,
            TeacherStudentChats: [],
            studentNotifications: [],
            openSingleModal: false,
            teacherId: "",
            unreadMessageCount: 0,
            studentNotificationCount: 0,
            priceRange: {
                minPrice: "",
                maxPrice: "",
            },
            TeacherLanguage: "English",
            TeacherCountry: "Any country",
            TeacherName: "",
            showTeachersComponent: 0,
            showBookPaidLessonComponent: false,
            trialLessonTransferDetails: "",
            // end of student states

            // teacher states
            showTeacherRegistrationFormComponent: 0,
            selectedDayIndex: 0,
            userSelectedTimezone: "",
            messageSideBar: false,
            teacherUnreadMessageCount: 0,
            teacherAdminUnreadMessageCount: 0,
            teacherNotificationCount: 0,
            loggedTeacher: "",
            StudentTeacherChats: [],
            teacherNotifications: [],
            studentId: "",
            TeacherMessageModalOpen: false,
            // end of teacher state
        };
    },

    mutations: {
        // Student Mutations
        trialLessonTransferDetails(state, payload) {
            state.trialLessonTransferDetails = payload.details;
        },

        trialLessonTransferStudentTimezone(state, payload) {
            state.trialLessonTransferDetails.student_timezone =
                payload.timezone;
        },
        buyHours(state) {
            state.showBookPaidLessonComponent = true;
        },
        closeBookPaidLessonComponent(state) {
            state.showBookPaidLessonComponent = false;
        },
        TeachersByPrice(state, payload) {
            state.priceRange.minPrice = payload.minPrice;
            state.priceRange.maxPrice = payload.maxPrice;
            state.showTeachersComponent = 0;
            setTimeout(() => {
                state.showTeachersComponent = 4;
            }, 500);
        },
        setPrice(state, payload) {
            state.priceRange.minPrice = payload.minPrice;
            state.priceRange.maxPrice = payload.maxPrice;
        },
        getTeachersByLanguage(state, payload) {
            state.TeacherLanguage = payload.language;
            state.showTeachersComponent = 0;
            setTimeout(() => {
                state.showTeachersComponent = 2;
            }, 500);
        },
        setLanguage(state, payload) {
            state.TeacherLanguage = payload.language;
        },
        setCountry(state, payload) {
            state.TeacherCountry = payload.country;
        },
        getTeachersByCountry(state, payload) {
            state.TeacherCountry = payload.country;
            state.showTeachersComponent = 0;
            setTimeout(() => {
                state.showTeachersComponent = 3;
            }, 500);
        },
        getTeachersByName(state, payload) {
            state.TeacherName = payload.name;
            state.showTeachersComponent = 0;
            setTimeout(() => {
                state.showTeachersComponent = 5;
            }, 500);
        },
        ActivateOtherTeacherDiv(state) {
            state.showTeachersComponent = 1;
        },
        ActivateOurTeacherDiv(state) {
            state.showTeachersComponent = 0;
            state.priceRange.minPrice = 1;
            state.priceRange.maxPrice = 40;
            state.TeacherLanguage = "English";
            state.TeacherCountry = "Any country";
            state.TeacherName = "";
        },

        teacherId(state, payload) {
            state.teacherId = payload;

            const teacher_id = payload;
            const modifiedChats = state.TeacherStudentChats.map((chat) => {
                return { ...chat, teacher_id };
            });
            state.TeacherStudentChats = modifiedChats;
        },

        TeacherStudentChats(state, payload) {
            const teacher_id = state.teacherId;
            const modifiedChats = payload.map((chat) => {
                return { ...chat, teacher_id };
            });

            state.TeacherStudentChats = modifiedChats;
            state.openSingleModal = true;
            state.openModal = false;
        },

        SingleMessageModalClose(state) {
            state.openSingleModal = false;
            state.openModal = true;
        },

        MessageModalPopup(state) {
            state.openModal = true;
        },
        MessageModalClose(state) {
            state.openModal = false;
        },
        userDetails(state, obtainedUser) {
            state.loggedUser = obtainedUser;
            state.studentNotifications = obtainedUser.student_notifications;
            state.studentNotificationCount = obtainedUser.notification_count;
            // state.isAuthenticated = true
        },
        profileImage(state, profileImage) {
            state.profileImageUrl = profileImage;
        },
        setFavoriteCount(state, payload) {
            state.FavoriteCount = payload;
        },
        addFavoriteCount(state, payload) {
            state.FavoriteCount++;
        },
        subtrFavoriteCount(state, payload) {
            state.FavoriteCount--;
        },
        unreadMessageCount(state, payload) {
            state.unreadMessageCount = payload;
        },
        reduceUnreadMessagesCount(state, payload) {
            state.unreadMessageCount -= payload;
        },

        studentNotificationCount(state, payload) {
            state.studentNotificationCount = payload;
        },

        resetStudentNotificationCount(state, payload) {
            state.studentNotificationCount = payload;
        },

        setStudentNotification(state, payload) {
            state.studentNotifications.unshift(payload);
            state.studentNotificationCount += 1;
        },

        setStudentMessage(state, payload) {
            const created_at = new Date();
            const message = {
                message: payload.message,
                created_at: created_at,
                to: payload.student_id,
                from: payload.teacher_id,
                teacher_id: payload.teacher_id,
            };
            state.TeacherStudentChats.push(message);
            state.unreadMessageCount += 1;
            emitter.emit("studentMessageArrival", message);
        },
        // end of student mutations

        // teacher mutations
        setMessageSideBarActive(state) {
            state.messageSideBar = !state.messageSideBar;
        },

        setDescriptionComponent(state) {
            state.showTeacherRegistrationFormComponent = 1;
        },
        setAboutComponent(state) {
            state.showTeacherRegistrationFormComponent = 0;
        },
        setQualificationComponent(state) {
            state.showTeacherRegistrationFormComponent = 2;
        },
        setVideoComponent(state) {
            state.showTeacherRegistrationFormComponent = 3;
        },
        setAvailabilityComponent(state) {
            state.showTeacherRegistrationFormComponent = 4;
        },

        setDayIndex(state, payload) {
            state.selectedDayIndex = payload.dayIndex;
        },

        setUserTimezone(state, payload) {
            state.userSelectedTimezone = payload.timezone;
        },

        // teacher message mutations not done yet
        StudentTeacherChats(state, payload) {
            const student_id = state.studentId;
            const modifiedChats = payload.map((chat) => {
                return { ...chat, student_id };
            });

            state.StudentTeacherChats = modifiedChats;
            state.TeacherMessageModalOpen = true;
            state.messageSideBar = false;
        },
        TeacherMessageModalClose(state) {
            state.TeacherMessageModalOpen = false;
            state.messageSideBar = true;
        },
        TeacherContactListClose(state) {
            state.messageSideBar = false;
        },

        teacherNotificationCount(state, payload) {
            state.teacherNotificationCount = payload;
        },
        resetTeacherNotificationCount(state, payload) {
            state.teacherNotificationCount = payload;
        },
        reduceTeacherUnreadMessagesCount(state, payload) {
            state.teacherUnreadMessageCount -= payload;
        },

        teacherDetails(state, obtainedUser) {
            state.loggedTeacher = obtainedUser;
            state.teacherUnreadMessageCount =
                obtainedUser.unread_messages_count.length;
            state.teacherNotificationCount = obtainedUser.notification_count;
            state.teacherNotifications = obtainedUser.teacher_notifications;
            state.teacherAdminUnreadMessageCount =
                obtainedUser.unread_admin_messages_count;
            // state.isAuthenticated = true
        },
        studentId(state, payload) {
            state.studentId = payload;
            const student_id = payload;
            const modifiedChats = state.StudentTeacherChats.map((chat) => {
                return { ...chat, student_id };
            });
            state.StudentTeacherChats = modifiedChats;
        },

        setTeacherMessage(state, payload) {
            const created_at = new Date();
            const message = {
                message: payload.message,
                created_at: created_at,
                to: payload.teacher_id,
                from: payload.student_id,
                student_id: payload.student_id,
            };

            state.StudentTeacherChats.push(message);
            state.teacherUnreadMessageCount += 1;
            emitter.emit("teacherMessageArrival", message);
        },

        setTeacherNotification(state, payload) {
            state.teacherNotifications.unshift(payload);
            state.teacherNotificationCount += 1;
        },

        // end of teacher mutations
    },
});

export default store;
