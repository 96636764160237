import { createRouter, createWebHistory } from "vue-router";

// Student routes
const TransferLessons = () => import(/* webpackChunkName: "transferLessons" */ "../components/Students/Booking/TransferLessons.vue");
const TransferTrialLessons = () => import(/* webpackChunkName: "transferTrialLessons" */ "../components/Students/Booking/TransferTrialLessons.vue");
const TransferTrialSchedule = () => import(/* webpackChunkName: "transferTrialSchedule" */ "../components/Students/Booking/TransferTrialSchedule.vue");
const EditStudentProfile = () => import(/* webpackChunkName: "editStudentProfile" */ "../components/Students/EditStudentProfile.vue");
const RightNavMain = () => import(/* webpackChunkName: "rightNavMain" */ "../components/RightNavMain.vue");
const StudentAd = () => import(/* webpackChunkName: "studentAd" */ "../components/Students/StudentAds/StudentAd.vue");
const StudentAdManagement = () => import(/* webpackChunkName: "studentAdManagement" */ "../components/Students/StudentAds/StudentAdManagement.vue");
const SingleAd = () => import(/* webpackChunkName: "singleAd" */ "../components/Students/StudentAds/SingleAd.vue");
const StudentDashboard = () => import(/* webpackChunkName: "studentDashboard" */ "../components/Students/StudentDashboard.vue");
const Messages = () => import(/* webpackChunkName: "messages" */ "../components/Students/Messages/Messages.vue");
const SingleMessage = () => import(/* webpackChunkName: "singleMessage" */ "../components/Students/Messages/SingleMessage.vue");
const FreelanceTeachers = () => import(/* webpackChunkName: "freelanceTeachers" */ "../components/Students/FreelanceTeachers.vue");
const SingleFreelanceTeacher = () => import(/* webpackChunkName: "singleFreelanceTeacher" */ "../components/Students/SingleFreelanceTeacher.vue");
const Favorites = () => import(/* webpackChunkName: "favorites" */ "../components/Students/Favorites/Favorites.vue");
const SingleFavorite = () => import(/* webpackChunkName: "singleFavorite" */ "../components/Students/Favorites/SingleFavorite.vue");
const BookTrialLesson = () => import(/* webpackChunkName: "bookTrialLesson" */ "../components/Students/Booking/BookTrialLesson.vue");
const IncompleteDemoBook = () => import(/* webpackChunkName: "incompleteDemoBook" */ "../components/Students/Booking/IncompleteDemoBook.vue");
const AllBookedLessons = () => import(/* webpackChunkName: "allBookedLessons" */ "../components/Students/Booking/AllBookedLessons.vue");
const DemoPaymentSummary = () => import(/* webpackChunkName: "demoPaymentSummary" */ "../components/Students/Booking/DemoPaymentSummary.vue");
const DemoValletSummary = () => import(/* webpackChunkName: "demoValletSummary" */ "../components/Students/Booking/DemoValletSummary.vue");
const PaidLessonPaymentSummary = () => import(/* webpackChunkName: "paidLessonPaymentSummary" */ "../components/Students/Booking/PaidLesson/PaidLessonPaymentSummary.vue");
const SchedulePaidLesson = () => import(/* webpackChunkName: "schedulePaidLesson" */ "../components/Students/Booking/PaidLesson/ScheduleLesson/SchedulePaidLesson.vue");
const ReschedulePaidLesson = () => import(/* webpackChunkName: "reschedulePaidLesson" */ "../components/Students/Booking/PaidLesson/ScheduleLesson/ReschedulePaidLesson.vue");
const SingleDemoLesson = () => import(/* webpackChunkName: "singleDemoLesson" */ "../components/Students/Booking/SingleDemoLesson.vue");
const LessonPackages = () => import(/* webpackChunkName: "lessonPackages" */ "../components/Students/Booking/PaidLesson/LessonPackages.vue");
const TrialLessonReschedule = () => import(/* webpackChunkName: "trialLessonReschedule" */ "../components/Students/Booking/RescheduleTrialCalendar.vue");
const StudentNotification = () => import(/* webpackChunkName: "studentNotification" */ "../components/Students/Notification/Index.vue");
const StudentSupport = () => import(/* webpackChunkName: "studentSupport" */ "../components/Students/Support/StudentSupport.vue");

// Teacher Routes
const TeacherDashboard = () => import(/* webpackChunkName: "teacherDashboard" */ "../components/Teachers/Home/TeacherDashboard.vue");
const RegistrationForm = () => import(/* webpackChunkName: "registrationForm" */ "../components/Teachers/Home/Register/RegistrationForm.vue");
const TeacherCalendar = () => import(/* webpackChunkName: "teacherCalendar" */ "../components/Teachers/Home/VerifiedTeacher/Pages/TeacherCalendar.vue");
const TeacherProfile = () => import(/* webpackChunkName: "teacherProfile" */ "../components/Teachers/Home/VerifiedTeacher/Pages/TeacherProfile.vue");
const TeacherProfileReviews = () => import(/* webpackChunkName: "teacherProfile" */ "../components/Teachers/Home/VerifiedTeacher/Pages/TeacherProfileReviews.vue");
const TeacherEarning = () => import(/* webpackChunkName: "teacherEarning" */ "../components/Teachers/Home/VerifiedTeacher/Pages/TeacherEarning.vue");
const LessonPlan = () => import(/* webpackChunkName: "lessonPlan" */ "../components/Teachers/Home/VerifiedTeacher/Pages/LessonPlan.vue");
const FindStudents = () => import(/* webpackChunkName: "findStudents" */ "../components/Teachers/Home/VerifiedTeacher/Pages/FindStudents.vue");
const FindTutors = () => import(/* webpackChunkName: "findTutors" */ "../components/Teachers/Home/VerifiedTeacher/Pages/FindTutors.vue");
const TeacherConfirmLesson = () => import(/* webpackChunkName: "teacherConfirmLesson" */ "../components/Teachers/Home/VerifiedTeacher/Pages/AboutLesson/TeacherConfirmLesson.vue");
const TrialLessonDetails = () => import(/* webpackChunkName: "trialLessonDetails" */ "../components/Teachers/Home/VerifiedTeacher/Pages/AboutLesson/TrialLessonDetails.vue");
const TeacherRescheduleTrial = () => import(/* webpackChunkName: "teacherRescheduleTrial" */ "../components/Teachers/Home/VerifiedTeacher/Pages/Lessons/RescheduleTrialCalendar.vue");
const TeacherSupport = () => import(/* webpackChunkName: "teacherSupport" */ "../components/Teachers/Home/VerifiedTeacher/Pages/Support/TeacherSupport.vue");
const TeacherPaymentMethod = () => import(/* webpackChunkName: "teacherPaymentMethod" */ "../components/Teachers/Home/VerifiedTeacher/Pages/TeacherPaymentMethod.vue");
const VerifyIdentity = () => import(/* webpackChunkName: "verifyIdentity" */ "../components/Teachers/Home/VerifiedTeacher/Pages/VerifyIdentity.vue");
const TeacherNotification = () => import(/* webpackChunkName: "teacherNotification" */ "../components/Teachers/Home/VerifiedTeacher/Pages/Notification/Index.vue");
const TeacherEarningHistory = () => import(/* webpackChunkName: "teacherEarningHistory" */ "../components/Teachers/Home/VerifiedTeacher/TeacherEarningHistory.vue");

// Admin routes
const AdminDashboard = () => import(/* webpackChunkName: "adminDashboard" */ "../components/Admin/Dashboard.vue");

// Landing routes
const LandingPage = () => import(/* webpackChunkName: "landingPage" */ "../components/Home/LandingPage/Index.vue");
const ErrorPage = () => import(/* webpackChunkName: "errorPage" */ "../components/404.vue");
const DataDeletion = () => import(/* webpackChunkName: "dataDeletion" */ "../components/Home/LandingPage/Pages/faqs/DataDeletion.vue");
const BookTeacher = () => import(/* webpackChunkName: "bookTeacher" */ "../components/Home/LandingPage/Pages/Book/BookTeacher.vue");
const TeacherSchedule = () => import(/* webpackChunkName: "bookTeacher" */ "../components/Home/LandingPage/Pages/Book/Teachers/TeacherSchedule.vue");
const Faq = () => import(/* webpackChunkName: "faq" */ "../components/Home/LandingPage/Pages/faqs/Index.vue");
const TermsAndConditions = () => import(/* webpackChunkName: "termsAndConditions" */ "../components/Home/LandingPage/Pages/faqs/TermsAndConditions.vue");
const PrivacyPolicy = () => import(/* webpackChunkName: "privacyPolicy" */ "../components/Home/LandingPage/Pages/faqs/PrivacyPolicy.vue");
const Cookies = () => import(/* webpackChunkName: "cookies" */ "../components/Home/LandingPage/Pages/faqs/Cookies.vue");
const CommissionPolicy = () => import(/* webpackChunkName: "commissionPolicy" */ "../components/Home/LandingPage/Pages/pricingplans/CommissionPolicy.vue");
const PricingOptions = () => import(/* webpackChunkName: "pricingOptions" */ "../components/Home/LandingPage/Pages/pricingplans/PricingOptions.vue");
const DiscountsAndOffers = () => import(/* webpackChunkName: "discountsAndOffers" */ "../components/Home/LandingPage/Pages/pricingplans/DiscountsAndOffers.vue");
const RefundPolicy = () => import(/* webpackChunkName: "refundPolicy" */ "../components/Home/LandingPage/Pages/pricingplans/RefundPolicy.vue");
const OurTeam = () => import(/* webpackChunkName: "ourTeam" */ "../components/Home/LandingPage/Pages/aboutus/OurTeam.vue");
const AboutUs = () => import(/* webpackChunkName: "aboutUs" */ "../components/Home/LandingPage/Pages/aboutus/AboutUs.vue");
const ContactUs = () => import(/* webpackChunkName: "contactUs" */ "../components/Home/LandingPage/Pages/aboutus/ContactUs.vue");
const Testimonials = () => import(/* webpackChunkName: "testimonials" */ "../components/Home/LandingPage/Pages/aboutus/Testimonials.vue");
const GrammarGuides = () => import(/* webpackChunkName: "grammarGuides" */ "../components/Home/LandingPage/Pages/studyresources/GrammarGuides.vue");
const VocabularyLists = () => import(/* webpackChunkName: "vocabularyLists" */ "../components/Home/LandingPage/Pages/studyresources/VocabularyLists.vue");
const LanguageExercises = () => import(/* webpackChunkName: "languageExercises" */ "../components/Home/LandingPage/Pages/studyresources/LanguageExercises.vue");
const LanguageTools = () => import(/* webpackChunkName: "languageTools" */ "../components/Home/LandingPage/Pages/studyresources/LanguageTools.vue");
const LanguageNewsPage = () => import(/* webpackChunkName: "languageNewsPage" */ "../components/Home/LandingPage/Pages/blogs/LanguageNewsPage.vue");
const BlogSuccessStories = () => import(/* webpackChunkName: "blogSuccessStories" */ "../components/Home/LandingPage/Pages/blogs/BlogSuccessStories.vue");
const BlogCulturalInsights = () => import(/* webpackChunkName: "blogCulturalInsights" */ "../components/Home/LandingPage/Pages/blogs/BlogCulturalInsights.vue");
const BlogLanguageLearningTips = () => import(/* webpackChunkName: "blogLanguageLearningTips" */ "../components/Home/LandingPage/Pages/blogs/BlogLanguageLearningTips.vue");
const ExploreOurPlatform = () => import(/* webpackChunkName: "exploreOurPlatform" */ "../components/Home/LandingPage/Pages/explore/ExploreOurPlatform.vue");


// const newLocal = "Home";
const routes = [
    // Landing routes
    {
        path: "/*",
        name: "ErrorPage",
        component: ErrorPage,
    },
    {
        path: "/trenglish/teacher/schedule/:teacher_id",
        components: { default: TeacherSchedule },
        props: { default: true },
    },
    {
        path: "/trenglish-academy/data-deletion",
        name: "DataDeletion",
        component: DataDeletion,
    },

    {
        path: "/",
        name: "LandingPage",
        component: LandingPage,
    },
    {
        path: "/trenglish/find-tutors",
        name: "BookTeacher",
        component: BookTeacher,
    },
    {
        path: "/trenglish-academy/language-news",
        name: "LanguageNewsPage",
        component: LanguageNewsPage,
    },
    {
        path: "/trenglish-academy/explore-our-platform",
        name: "ExploreOurPlatform",
        component: ExploreOurPlatform,
    },
    {
        path: "/trenglish-academy/success-stories",
        name: "BlogSuccessStories",
        component: BlogSuccessStories,
    },
    {
        path: "/trenglish-academy/cultural-insights",
        name: "BlogCulturalInsights",
        component: BlogCulturalInsights,
    },
    {
        path: "/trenglish-academy/language-learning-tips",
        name: "BlogLanguageLearningTips",
        component: BlogLanguageLearningTips,
    },
    {
        path: "/trenglish-academy/faq",
        name: "Faq",
        component: Faq,
    },
    {
        path: "/trenglish-academy/grammar-guides",
        name: "GrammarGuides",
        component: GrammarGuides,
    },

    {
        path: "/trenglish-academy/vocabulary-lists",
        name: "VocabularyLists",
        component: VocabularyLists,
    },
    {
        path: "/trenglish-academy/language-exercises",
        name: "LanguageExercises",
        component: LanguageExercises,
    },
    {
        path: "/trenglish-academy/language-tools",
        name: "LanguageTools",
        component: LanguageTools,
    },
    {
        path: "/trenglish-academy-terms-conditions",
        name: "TermsAndConditions",
        component: TermsAndConditions,
    },
    {
        path: "/trenglish-academy/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
    },
    {
        path: "/trenglish-academy/cookies",
        name: "Cookies",
        component: Cookies,
    },
    {
        path: "/trenglish-academy/discounts-and-offers",
        name: "DiscountsAndOffers",
        component: DiscountsAndOffers,
    },
    {
        path: "/trenglish-academy/pricing-options",
        name: "PricingOptions",
        component: PricingOptions,
    },
    {
        path: "/trenglish-academy/commission-policy",
        name: "CommissionPolicy",
        component: CommissionPolicy,
    },
    {
        path: "/trenglish-academy/refund-policy",
        name: "RefundPolicy",
        component: RefundPolicy,
    },
    {
        path: "/trenglish-academy/our-team",
        name: "OurTeam",
        component: OurTeam,
    },
    {
        path: "/trenglish-academy/about-us",
        name: "AboutUs",
        component: AboutUs,
    },
    {
        path: "/trenglish-academy/contact-us",
        name: "ContactUs",
        component: ContactUs,
    },
    {
        path: "/trenglish-academy/testimonials",
        name: "Testimonials",
        component: Testimonials,
    },
    // landing routes

    // admins routes
    {
        path: "/admin/dashboard",
        name: "adminDashboard",
        component: AdminDashboard,
    },
    // {
    //     path: "/admin/dashboard/single-teacher/:teacherId",
    //     name: "adminDashboardSingleTeacher",
    //     component: SingleTeacher,
    //     props: {default: true},
    // },

    // end admin routes

    // teacher dashboard
    {
        path: "/teacher/dashboard",
        name: "TeacherDashboard",
        component: TeacherDashboard,
    },
    {
        path: "/teacher/support",
        name: "TeacherSupport",
        component: TeacherSupport,
    },
    {
        path: "/student/support",
        name: "StudentSupport",
        component: StudentSupport,
    },
    {
        path: "/teacher/teacher-earning-history",
        name: "TeacherEarningHistory",
        component: TeacherEarningHistory,
    },
    {
        path: "/teacher/notifications/:slug",
        components: { default: TeacherNotification },
        props: { default: true },
    },
    {
        path: "/teacher/registration/form",
        name: "RegistrationForm",
        component: RegistrationForm,
    },
    {
        path: "/teacher/calendar",
        name: "TeacherCalendar",
        component: TeacherCalendar,
    },
    {
        path: "/teacher/find-students",
        name: "FindStudents",
        component: FindStudents,
    },
    {
        path: "/teacher/find-tutors",
        name: "FindTutors",
        component: FindTutors,
    },
    {
        path: "/teacher/lesson-plan",
        name: "LessonPlan",
        component: LessonPlan,
    },
    {
        path: "/teacher/profile-edit",
        name: "TeacherProfile",
        component: TeacherProfile,
    },
    {
        path: "/teacher/profile-reviews",
        name: "TeacherProfileReviews",
        component: TeacherProfileReviews,
    },
    {
        path: "/teacher/teacher-earnings",
        name: "TeacherEarning",
        component: TeacherEarning,
    },
    {
        path: "/teacher/trial/lesson/confirmation/:id",
        // name: 'TeacherConfirmLesson',
        components: { default: TeacherConfirmLesson },
        props: { default: true },
    },
    {
        path: "/teacher/verify-identity",
        // name: 'TeacherConfirmLesson',
        components: { default: VerifyIdentity },
        props: { default: true },
    },
    {
        path: "/teacher/withdraw-method/:payment_method",
        // name: 'TeacherConfirmLesson',
        components: { default: TeacherPaymentMethod },
        props: { default: true },
    },
    {
        path: "/teacher/trial/lesson/details/:id",
        // name: 'TeacherConfirmLesson',
        components: { default: TrialLessonDetails },
        props: { default: true },
    },
    {
        path: "/teacher/reschedule/trial-lesson/:id/:student_id",
        components: { default: TeacherRescheduleTrial },
        props: { default: true },
    },
    // end of teacher dashboard
    // {
    //     path: "/",
    //     name: "RightNavMain",
    //     component: RightNavMain,
    // },

    {
        path: "/student/home",
        name: "RightNavMain",
        component: RightNavMain,
    },
    {
        path: "/student/favorite",
        name: " Favorites",
        component: Favorites,
    },
    {
        path: "/student/single/favorite/:id",
        components: { default: SingleFavorite },
        props: { default: true },
    },
    {
        path: "/student/notifications/:slug",
        components: { default: StudentNotification },
        props: { default: true },
    },
    {
        path: "/student/dashboard",
        name: "StudentDashboard",
        component: StudentDashboard,
    },
    {
        path: "/student/create/ad",
        name: "StudentAd",
        component: StudentAd,
    },
    {
        path: "/student/edit/profile",
        name: "EditStudentProfile",
        component: EditStudentProfile,
    },
    {
        path: "/student/ad/management",
        name: "StudentAdmanagement",
        component: StudentAdManagement,
    },
    {
        path: "/student/single/ad/:id",
        components: { default: SingleAd },
        props: { default: true },
    },
    {
        path: "/student/messages",
        name: "Messages",
        component: Messages,
    },
    // {
    //     path: '/student/teachers',
    //     name: "Teachers",
    //     component: Teachers
    // },
    {
        path: "/student/freelance/teachers",
        name: "FreelanceTeachers",
        component: FreelanceTeachers,
    },
    {
        path: "/student/single/teacher/:id",
        components: { default: SingleFreelanceTeacher },
        props: { default: true },
    },
    {
        path: "/student/single/teacher/messages/:id",
        components: { default: SingleMessage },
        props: { default: true },
    },
    {
        path: "/student/freelance/single/teacher/:id",
        components: { default: SingleFreelanceTeacher },
        props: { default: true },
    },
    {
        path: "/student/transfer/lesson/:teacher_to_id/:teacher_from_id",
        components: { default: TransferLessons },
        props: { default: true },
    },
    {
        path: "/student/transfer/trial/lesson/:teacher_to_id/:teacher_from_id",
        components: { default: TransferTrialLessons },
        props: { default: true },
    },
    {
        path: "/student/confirm/reschedule/trial-lesson/:lesson_id/:teacher_id",
        components: { default: TrialLessonReschedule },
        props: { default: true },
    },
    {
        path: "/student/transfer/trial/lesson/schedule",
        components: { default: TransferTrialSchedule },
        props: { default: true },
    },
    {
        path: "/student/book/demo/lesson/:id",
        components: { default: BookTrialLesson },
        props: { default: true },
    },
    {
        path: "/student/book/paid/lesson/:id",
        components: { default: LessonPackages },
        props: { default: true },
    },
    {
        path: "/student/schedule/paid/lesson/:id",
        components: { default: SchedulePaidLesson },
        props: { default: true },
    },

    {
        path: "/student/reschedule/paid-lesson/:id/:teacherId",
        components: { default: ReschedulePaidLesson },
        props: { default: true },
    },
    {
        path: "/student/view/demo/lesson:id",
        components: { default: SingleDemoLesson },
        props: { default: true },
    },
    {
        path: "/student/demo/booking/payment/summary/:id",
        components: { default: DemoPaymentSummary },
        props: { default: true },
    },
    {
        path: "/student/demo-vallet/booking/payment/summary/:id",
        components: { default: DemoValletSummary },
        props: { default: true },
    },
    {
        path: "/student/paidlesson/booking/payment/summary/:id",
        components: { default: PaidLessonPaymentSummary },
        props: { default: true },
    },
    {
        path: "/student/incomplete/demo/booking",
        name: "IncompleteDemoBook",
        components: { default: IncompleteDemoBook },
    },
    {
        path: "/student/all/booked/lessons",
        name: "AllBookedLessons",
        components: { default: AllBookedLessons },
    },
    // teacher routes
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    // scrollBehavior to scroll to the top
     scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

export default router;
