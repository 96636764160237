// require('./bootstrap');

import { createApp } from 'vue'
import VueApp from '../components/VueApp.vue'
import './bootstrap'

import router from '../vuex/routes.js'
import store from '../vuex/vuex'
import Vuesax from 'vuesax3'
import 'vuesax3/dist/vuesax.css'

import 'material-icons/iconfont/material-icons.css';
import mitt from 'mitt';
import Toast from "vue-toastification";
import 'vue-toastification/dist/index.css';
import '@mdi/font/css/materialdesignicons.css';
import VueEasyLightbox from 'vue-easy-lightbox'
import { MotionPlugin } from '@vueuse/motion'
import { i18nVue } from "laravel-vue-i18n";
import { VueHeadMixin, createHead } from "@unhead/vue";

const emitter = mitt();

const app = createApp(VueApp)
app.config.globalProperties.emitter = emitter

const head = createHead();

app.use(router)
app.use(head);
app.mixin(VueHeadMixin);

app.use(VueEasyLightbox)
app.use(MotionPlugin)
app.use(Vuesax, {
    theme: {
        colors: {
            success: '#029e20',
            danger: 'rgb(242, 19, 93)',
            warning: 'rgb(255, 130, 0)',
            dark: '#183153',
            yellow: '#fed907'
        }
    }
})
app.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true,
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.7,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
});
app.use(i18nVue, {
    resolve: (lang) => import(`../lang/${lang}.json`),
});
app.use(store)
app.mount('#vueapp')