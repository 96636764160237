const admin = {
 namespaced: true,
 state: {
  AdminCurrentActivePage: 'newTeachers',
  teacherId: '',
  requestId: '',
 },
 mutations: {
  setPage(state, payload) {
   state.AdminCurrentActivePage = payload.page;
  },
  setTeacherId(state, payload) {
   state.teacherId = payload.teacherId;
   state.AdminCurrentActivePage = payload.page
  },
  setWithdrawId(state, payload) {
   state.requestId = payload.requestId;
   state.AdminCurrentActivePage = payload.page;
  }

 }

}

export default admin;